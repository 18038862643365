<template>
  <div class="report min-h-contain">
    <h1 class="text-3xl font-bold flex items-end gap-2 mb-2">Class Schedule Report</h1>
    <div class="flex items-center gap-2 mb-8">
      <router-link to="/jadwalkelas" class="text-yellow font-medium text-sm">Class Schedule</router-link>
      <Chevron direction="right" width="10" />
      <p class="text-sm">Class Schedule Report</p>
    </div>
    <div class="mdmax:overflow-x-auto mdmax:w-1/2 bg-white p-6 rounded-lg shadow-small">
      <TableComponent :columns="columns" :list="activityItemList" compact>
        <template slot="table-row" slot-scope="{ entry, column }">
          <div v-if="column.field === 'action'" class="text-right flex flex-col gap-2 items-end">
            <template v-if="entry.meeting_link_auto_generated">
              <Button buttonText="Start Meeting" type="secondary" size="regular" additionalClass="w-40" @action="joinAsHost(entry)" />
            </template>
            <template v-else>
              <Button buttonText="Join Webinar" type="secondary" size="regular" additionalClass="w-40" @action="joinWebinar(entry.link)" />
            </template>
          </div>
          <div v-else-if="column.field === 'schedule'">
            <p>{{ getDate(entry.startTime) }}</p>
            <p class="text-smallest text-neutral-400">{{ getTime(entry.startTime, entry.endTime) }}</p>
          </div>
          <div v-else>{{ entry[column.field] || '-' }}</div>
        </template>
      </TableComponent>
    </div>
    <div class="flex items-center justify-center" :class="[!visibleZoom ? 'hidden -z-1' : 'show-zoom']">
      <div class="text-white px-6 py-2 border border-white rounded-lg cursor-pointer fixed text-sm font-bold" v-if="visibleCloseSession" @click="closeSession()">Close Session</div>
      <div id="meetingSDKElement" ref="zoomClient" class=""></div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { showVueToast } from '@/utils'
import ZoomMtgEmbedded from '@zoomus/websdk/embedded'
import { scrollToTheTarget } from '../../utils/scroll'
const WEBINARITEMTYPE = 'WEBINAR'
export default {
  components: {
    Chevron: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Chevron'),
    TableComponent: () => import(/* webpackChunkName: "Simple-Table" */ '@/components/Table/SimpleTable'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button')
  },
  data() {
    return {
      columns: [
        { label: 'Activity Title', field: 'title' },
        { label: 'Webinar Schedule', field: 'schedule' },
        { label: 'Webinar Link', field: 'link' },
        { label: 'Account', field: 'meeting_account_auto_generated' },
        { label: 'Action', field: 'action', position: 'center' }
      ],
      activityItemList: [],
      sdkKey: process.env.VUE_APP_ZOOM_SDK_KEY,
      leaveUrl: window.location.href,
      meetingNumber: '',
      passWord: '',
      role: 1,
      signatureEndpoint: '',
      userEmail: '',
      userName: '',
      registrantToken: '',
      client: ZoomMtgEmbedded.createClient(),
      visibleZoom: false,
      visibleCloseSession: false
    }
  },
  computed: {
    ...mapGetters('customer', ['customer']),
    lmsClassId() {
      return this.$route.params.id
    },
    customerId() {
      return localStorage.getItem('client')
    }
  },
  created() {
    this.getScheduleReportList()
  },
  mounted() {},
  methods: {
    ...mapActions('jadwalkelas', ['GET_JADWALKELAS_WITH_PARAMS']),
    ...mapActions('integration', ['GET_ZOOM_SETTING', 'GET_ZAK_TOKEN', 'GET_SIGNATURE']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    joinAsHost(data) {
      this.meetingNumber = data.meeting_id_auto_generated
      this.userEmail = data.meeting_account_auto_generated
      this.getSignature()
    },
    getSignature() {
      this.showLoading()
      this.GET_SIGNATURE({
        payload: {
          meetingNumber: this.meetingNumber,
          role: this.role
        }
      })
        .then((res) => {
          this.getZakToken(res.data.signature)
        })
        .catch((error) => {
          this.hideLoading()
          showVueToast(error.errors.error, 'error', 2000)
        })
    },
    getZakToken(signature) {
      this.GET_ZAK_TOKEN({
        customerId: this.customerId,
        payload: {
          email: this.userEmail
        }
      })
        .then((res) => {
          this.hideLoading()
          this.startMeeting(signature, res.data.zakToken)
        })
        .catch((error) => {
          this.hideLoading()
          showVueToast(error, 'error', 2000)
        })
    },
    startMeeting(signature, zakToken) {
      this.visibleZoom = true
      this.showLoading()
      let meetingSDKElement = document.getElementById('meetingSDKElement')
      this.client.init({
        zoomAppRoot: meetingSDKElement,
        language: 'en-US',
        customize: {
          meetingInfo: ['topic', 'host', 'mn', 'pwd', 'telPwd', 'invite', 'participant', 'dc', 'enctype'],
          video: {
            // popper: {
            //   disableDraggable: true
            // },
            viewSizes: {
              default: {
                width: 600,
                height: 342
              }
            }
          }
        }
      })
      this.client
        .join({
          sdkKey: this.sdkKey,
          signature: signature,
          meetingNumber: this.meetingNumber,
          password: 'joinMe!',
          userName: this.customer?.name,
          userEmail: this.customer?.email,
          zak: zakToken
        })
        .then((e) => {
          // scrollToTheTarget('#meetingSDKElement', 100)
          this.hideLoading()
          this.visibleCloseSession = true
        })
        .catch(() => {
          this.hideLoading()
          showVueToast('Failed to start the meeting, please try again!', 'error', 2000)
          this.visibleCloseSession = true
        })
    },
    closeSession() {
      this.visibleZoom = false
      this.visibleCloseSession = false
      location.reload()
    },
    getZoomSetting() {
      this.GET_ZOOM_SETTING()
    },
    getScheduleReportList() {
      this.showLoading()
      this.GET_JADWALKELAS_WITH_PARAMS({
        lmsClassId: this.lmsClassId,
        params: {
          activityItemType: WEBINARITEMTYPE,
          customerId: this.customerId
        }
      }).then((res) => {
        const activities = res.data?.data?.activities || []
        activities.forEach((element) => {
          element.activityItems.forEach((item) => this.activityItemList.push(item))
        })
        this.hideLoading()
      })
    },
    getDate(value) {
      return moment(value).format('DD MMM YYYY')
    },
    getTime(startDate, endDate) {
      return `${moment(startDate).format('HH:mm')} - ${moment(endDate).format('HH:mm')} WIB`
    },
    joinWebinar(link) {
      window.open(link)
    }
  }
}
</script>
<style lang="scss" scoped>
.zoom {
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 99;
}
.show-zoom {
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 21;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
</style>
