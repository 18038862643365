var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "report min-h-contain"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold flex items-end gap-2 mb-2"
  }, [_vm._v("Class Schedule Report")]), _c('div', {
    staticClass: "flex items-center gap-2 mb-8"
  }, [_c('router-link', {
    staticClass: "text-yellow font-medium text-sm",
    attrs: {
      "to": "/jadwalkelas"
    }
  }, [_vm._v("Class Schedule")]), _c('Chevron', {
    attrs: {
      "direction": "right",
      "width": "10"
    }
  }), _c('p', {
    staticClass: "text-sm"
  }, [_vm._v("Class Schedule Report")])], 1), _c('div', {
    staticClass: "mdmax:overflow-x-auto mdmax:w-1/2 bg-white p-6 rounded-lg shadow-small"
  }, [_c('TableComponent', {
    attrs: {
      "columns": _vm.columns,
      "list": _vm.activityItemList,
      "compact": ""
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(_ref) {
        var entry = _ref.entry,
            column = _ref.column;
        return [column.field === 'action' ? _c('div', {
          staticClass: "text-right flex flex-col gap-2 items-end"
        }, [entry.meeting_link_auto_generated ? [_c('Button', {
          attrs: {
            "buttonText": "Start Meeting",
            "type": "secondary",
            "size": "regular",
            "additionalClass": "w-40"
          },
          on: {
            "action": function action($event) {
              return _vm.joinAsHost(entry);
            }
          }
        })] : [_c('Button', {
          attrs: {
            "buttonText": "Join Webinar",
            "type": "secondary",
            "size": "regular",
            "additionalClass": "w-40"
          },
          on: {
            "action": function action($event) {
              return _vm.joinWebinar(entry.link);
            }
          }
        })]], 2) : column.field === 'schedule' ? _c('div', [_c('p', [_vm._v(_vm._s(_vm.getDate(entry.startTime)))]), _c('p', {
          staticClass: "text-smallest text-neutral-400"
        }, [_vm._v(_vm._s(_vm.getTime(entry.startTime, entry.endTime)))])]) : _c('div', [_vm._v(_vm._s(entry[column.field] || '-'))])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "flex items-center justify-center",
    class: [!_vm.visibleZoom ? 'hidden -z-1' : 'show-zoom']
  }, [_vm.visibleCloseSession ? _c('div', {
    staticClass: "text-white px-6 py-2 border border-white rounded-lg cursor-pointer fixed text-sm font-bold",
    on: {
      "click": function click($event) {
        return _vm.closeSession();
      }
    }
  }, [_vm._v("Close Session")]) : _vm._e(), _c('div', {
    ref: "zoomClient",
    attrs: {
      "id": "meetingSDKElement"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }